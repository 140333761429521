var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InformationIconBadge", {
    attrs: {
      background: _vm.blueColor,
      color: _vm.whiteColor,
      title: "READ ONLY"
    },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function(ref) {
          var color = ref.color
          return [_c("IconLock", { attrs: { "fill-color": color } })]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }