var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "InformationBadge",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "prepend",
              fn: function() {
                return [_vm._t("icon", null, { color: _vm.$attrs.color })]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "InformationBadge",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }