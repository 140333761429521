var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select", {
    attrs: {
      value: _vm.selectedOptions,
      options: _vm.options,
      type: _vm.type,
      alignment: _vm.alignment,
      size: _vm.size,
      "fixed-content": _vm.fixedContent,
      dismissible: _vm.dismissible,
      label: _vm.label,
      placeholder: _vm.placeholder,
      "error-messages": _vm.errorMessages,
      "search-value": _vm.searchValue,
      hint: _vm.hint,
      required: _vm.required,
      autofocus: _vm.autofocus,
      disabled: _vm.disabled,
      clearable: _vm.clearable,
      multiselect: _vm.multiselect,
      searchable: _vm.searchable,
      "wrap-value": _vm.wrapValue,
      "option-key": _vm.optionKey,
      "option-value": _vm.optionValue,
      "data-cy": _vm.dataCy
    },
    on: { focus: _vm.onFocus, search: _vm.onSearch, input: _vm.onValueChange },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend",
          fn: function() {
            return [_vm._t("prepend")]
          },
          proxy: true
        },
        {
          key: "value",
          fn: function() {
            return [
              _vm._t(
                "value",
                function() {
                  return [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.parsedValue) }
                    })
                  ]
                },
                { selectedOptions: _vm.selectedOptions }
              )
            ]
          },
          proxy: true
        },
        {
          key: "append",
          fn: function() {
            return [
              _vm._t("append"),
              _vm._v(" "),
              _c(
                "FadeTransition",
                [
                  _vm.isFetchingData
                    ? _c("IconSpinner", { attrs: { color: _vm.graphiteColor } })
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "dropdownBody",
          fn: function() {
            return [_vm.isPreloaderVisible ? _c("Preloader") : _vm._e()]
          },
          proxy: true
        },
        {
          key: "noDataPlaceholder",
          fn: function() {
            return [_vm._t("noDataPlaceholder")]
          },
          proxy: true
        },
        {
          key: "noResultsPlaceholder",
          fn: function() {
            return [_vm._t("noResultsPlaceholder")]
          },
          proxy: true
        },
        {
          key: "details",
          fn: function() {
            return [_vm._t("details")]
          },
          proxy: true
        },
        {
          key: "option",
          fn: function(ref) {
            var option = ref.option
            var isSelected = ref.isSelected
            return [
              _vm._t(
                "option",
                function() {
                  return [
                    _vm.multiselect
                      ? _c(
                          "ListElementAction",
                          { attrs: { size: _vm.size } },
                          [_c("CheckBox", { attrs: { value: isSelected } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ListElementDescription",
                      [
                        _c("ListElementTitle", {
                          attrs: {
                            size: _vm.size,
                            title: option.label || "#" + option.code
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                { option: option, isSelected: isSelected }
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }