var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("Fab", {
        style: { backgroundColor: _vm.whiteColor },
        attrs: { theme: _vm.secondaryTheme },
        nativeOn: {
          click: function($event) {
            return _vm.onPreviousItem.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [
                _c("IconArrowSingle", {
                  attrs: { state: _vm.arrowLeft, "fill-color": color }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("Fab", {
        style: { backgroundColor: _vm.whiteColor },
        attrs: { theme: _vm.secondaryTheme },
        nativeOn: {
          click: function($event) {
            return _vm.onTextItem.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [
                _c("IconArrowSingle", {
                  attrs: { state: _vm.arrowRight, "fill-color": color }
                })
              ]
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }