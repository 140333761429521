var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, style: _vm.styles },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c("BadgeLabel", { attrs: { label: _vm.title } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }