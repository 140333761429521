var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RoundedBadge",
    { staticClass: "notification-badge" },
    [_c("BadgeLabel", { attrs: { label: _vm.info } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }