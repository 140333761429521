var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("span", {
    staticClass: "badge-label",
    domProps: { textContent: _vm._s(_vm.props.label) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }