var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      style: _vm.floatingStyle,
      attrs: { disabled: _vm.disabled, type: "button", "aria-label": _vm.title }
    },
    [
      _vm._t("prepend", null, { color: _vm.foregroundColor }),
      _vm._v(" "),
      _vm.title
        ? _c("span", {
            class: _vm.titleClasses,
            domProps: { textContent: _vm._s(_vm.title) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("append", null, { color: _vm.foregroundColor }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }