var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm.title
      ? _c(
          "section",
          { staticClass: "card__header" },
          [
            _c("h2", {
              staticClass: "card__title",
              domProps: { textContent: _vm._s(_vm.title) }
            }),
            _vm._v(" "),
            _vm._t("appendHeader")
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "card__body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }